import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    cuit: '',
    nombre: '',
    email: '',
    celular: '',
    // tipoDocumento: null,
    // numeroDocumento: '',
    campos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.id = '';
        state.cuit = '';
        state.nombre = '';
        state.email = '';
        state.celular = '';
        // state.tipoDocumento = null;
        // state.numeroDocumento = '';
        state.campos = [];
    },
    'SET_DATOS'(state, contribuyente) {
        state.id = contribuyente.id;
        state.cuit = contribuyente.cuit;
        state.nombre = contribuyente.nombre;
        state.email = contribuyente.email;
        state.celular = contribuyente.celular;
        // state.tipoDocumento = solicitud.tipoDocumento;
        // state.numeroDocumento = solicitud.numeroDocumento;
        state.campos = contribuyente.campos;
    },
};

const actions = {
    getDatos ( { commit }, contribuyenteId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (contribuyenteId) {
                apiAxios.get(`contribuyentes/${contribuyenteId}`) 
                    .then(res => {
                        commit('SET_DATOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },
    getDatosByCuit ( { commit }, cuit ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            apiAxios.get(`contribuyentes/cuit/${cuit}`) 
                .then(res => {
                    if (res.status == 200) {
                        commit('SET_DATOS', res.data);
                        resolve();
                    } else {
                        resolve();
                    }
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },    
};

const getters = {
    getField,

    contribuyenteId(state) {
        return state.id;
    },
    nombre(state) {
        return state.nombre;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
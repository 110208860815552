import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    rowVersion: null,

    valores: [],
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, listaId) {
        state.id = listaId;
        state.nombre = '';
        state.rowVersion = null;

        state.valores = [];
    },
    'SET_LISTA'(state, lista) {
        state.nombre = lista.nombre.trim();
        state.rowVersion = lista.rowVersion;

        state.valores = lista.valores.map(valor => ({
            nombre: valor.nombre.trim()
        }));
    },
    'ADD_VALOR' (state, valor) {
        let itemKey = state.valores.findIndex(t => t.nombre == valor);

        if (itemKey == -1) {
            state.valores.push({
                nombre: valor.toUpperCase().trim(),
            })
        }
    },
    'REMOVE_VALOR' (state, valor) {
        let itemKey = state.valores.findIndex(t => t.nombre == valor);
        
        if (itemKey != -1) {
            state.valores.splice(itemKey, 1);
        }
    }
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, listaId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', listaId);
            
            if (listaId != '') {
                apiAxios.get(`listas/${listaId}/abm`) 
                    .then(res => {
                        commit('SET_LISTA', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    addValor ( { commit }, valor ) {
        return new Promise((resolve, reject) => {
            commit('ADD_VALOR', valor);
            resolve();
        })
    },
    updateCampo ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('UPDATE_CAMPO', campo);
            resolve();
        })
    },
    removeValor ( { commit }, valor ) {
        return new Promise((resolve, reject) => {
            commit('REMOVE_VALOR', valor);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let lista= {
                    nombre: state.nombre.toUpperCase().trim(),
                    valores: state.valores.map(valor => ({
                        nombre: valor.nombre.toUpperCase().trim(),
                    }))
                };

                let url = 'listas';
                if (state.id != '') {
                    url += '/' + state.id;
                    lista.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, lista)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import { getField, updateField } from 'vuex-map-fields';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    campoTipoId: 0,
    campoTipo: null,
    campoTipoNombre: null,
    longitudMaxima: 0,
    orden: 0,

    /* Propiedades del tipo de campo */
    requiereLongitud: false,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.id = '';
        state.nombre = '';
        state.campoTipoId = 0;
        state.campoTipo = null;
        state.campoTipoNombre = null;
        state.longitudMaxima = 0;
        state.orden = 0;

        state.requiereLongitud = false;
    },
    'SET_CAMPO_GRUPO'(state, campo) {
        state.id = campo.id;
        state.nombre = campo.nombre;
        state.campoTipoId = campo.campoTipo? campo.campoTipo.id : 0;
        state.campoTipo = campo.campoTipo;
        state.campoTipoNombre = campo.campoTipo? campo.campoTipo.nombre.trim() : null;
        state.longitudMaxima = campo.longitudMaxima;
        state.orden = campo.orden;
        
        state.requiereLongitud = (/true/i).test(campo.campoTipo? campo.campoTipo.requiereLongitud : false);
    },
    'SET_CAMPO_TIPO'(state, campoTipo) {
        state.campoTipo = campoTipo;
        if (campoTipo) {
            state.campoTipoId = campoTipo.id? campoTipo.id : 0;
            state.campoTipoNombre = campoTipo.nombre? campoTipo.nombre.trim() : null;
            
            state.requiereLongitud = (/true/i).test(campoTipo.requiereLongitud);

            /* Reinicializamos los parametros de configuración */
            if (!state.requiereLongitud) {
                state.longitudMaxima = 0;
            }
        } else {
            state.campoTipoId = 0;
            state.campoTipoNombre = null;
            
            state.requiereLongitud = false;
            state.longitudMaxima = 0;
        }
    },
};

const getters = {
    getField,

    formularioCampoGrupo (state) {
        return {
            id: state.id,
            nombre: state.nombre.trim(),
            campoTipo: state.campoTipo,
            longitudMaxima: parseInt(state.longitudMaxima),
            orden: parseInt(state.orden),
        }
    }
};

const actions = {
    inicializar ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            if (campo) {
                commit('SET_CAMPO_GRUPO', campo);
                resolve();
            } else {
                resolve();
            }
        })
    },
    setCampoTipo ( { commit }, campoTipo ) {
        return new Promise((resolve, reject) => {
            commit('SET_CAMPO_TIPO', campoTipo);
            resolve();
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import solicitud from './solicitud';

const state = {
    solicitudes: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
    programa: null,
    solicitudEstado: null,
    departamento: null,
    proceso: null,
    procesoEstado: null,
    numeroLegajo: '',
    numeroSolicitud: '',
    numeroDocumento: '',
    sortByDescending: true,

    usuarios: []
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.solicitudes = [];
    },
    'SET_SOLICITUDES'(state, solicitudes) {
        state.solicitudes = solicitudes.map(solicitud => ({
            ...solicitud,
            _rowVariant: solicitud.visto? 'leido' : 'no-leido'
        }));
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_USUARIOS'(state) {
        state.usuarios = [];
    },
    'SET_USUARIOS'(state, usuarios) {
        state.usuarios = usuarios;
    },
};

const actions = {
    getSolicitudes: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;

            if (state.programa) {
                if (state.programa.id) {
                    queryString += '&programaId=' + state.programa.id;
                }
            }
    
            if (state.solicitudEstado) {
                if (state.solicitudEstado.id) {
                    queryString += '&solicitudEstadoId=' + state.solicitudEstado.id;
                }
            }

            if (state.departamento) {
                if (state.departamento.id) {
                    queryString += '&departamentoId=' + state.departamento.id;
                }
            }

            if (state.proceso) {
                if (state.proceso.id) {
                    queryString += '&procesoId=' + state.proceso.id;
                }
            }

            if (state.procesoEstado) {
                if (state.procesoEstado.id) {
                    queryString += '&procesoEstadoId=' + state.procesoEstado.id;
                }
            }

            if (state.numeroDocumento != '') {
                queryString += '&numeroDocumento=' + state.numeroDocumento.trim();
            }

            if (state.numeroLegajo != '') {
                queryString += '&numeroLegajo=' + state.numeroLegajo.trim();
            }

            apiAxios.get('solicitudes' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_SOLICITUDES', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    // actualizarDepartamento: ( {}, params ) => {
    //     return new Promise((resolve, reject) => {
    //         let actualizacionEstado = {
    //             departamentoId: params.departamentoId,
    //             observaciones: params.observaciones.trim()
    //         }

    //         apiAxios.post( `solicitudes/${params.solicitudId}/actualizacion-departamento`, actualizacionEstado)
    //             .then(() => resolve())
    //             .catch(error => reject(helpers.methods.getErrorMessage(error)));
    //     })
    // },
    // actualizarProceso: ( {}, params ) => {
    //     return new Promise((resolve, reject) => {
    //         let actualizacionEstado = {
    //             procesoId: params.procesoId,
    //             observaciones: params.observaciones.trim()
    //         }

    //         apiAxios.post( `solicitudes/${params.solicitudId}/actualizacion-proceso`, actualizacionEstado)
    //             .then(() => resolve())
    //             .catch(error => reject(helpers.methods.getErrorMessage(error)));
    //     })
    // },
    // actualizarEstado: ( {}, params ) => {
    //     return new Promise((resolve, reject) => {
    //         let actualizacionEstado = {
    //             procesoEstadoId: params.procesoEstadoId,
    //             observaciones: params.observaciones.trim()
    //         }

    //         apiAxios.post( `solicitudes/${params.solicitudId}/actualizacion-estado`, actualizacionEstado)
    //             .then(() => resolve())
    //             .catch(error => reject(helpers.methods.getErrorMessage(error)));
    //     })
    // },
    delete: ( {}, solicitudId ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `solicitudes/${solicitudId}/delete`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getUsuarios: ( {}, solicitudId ) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_USUARIOS');

            apiAxios.post( `solicitudes/${solicitudId}/usuarios`)
                .then(() => {
                    commit('SET_USUARIOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    asignarUsuario: ( {}, params ) => {
        return new Promise((resolve, reject) => {
            let asignacion = {
                usuarioId: params.usuarioId,
                observaciones: params.observaciones.trim()
            }

            apiAxios.post( `solicitudes/${params.solicitudId}/usuarios`, asignacion)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    finalizarAsignacionUsuario: ( {}, params ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `solicitudes/${params.solicitudId}/usuarios/${params.usuarioId}/finalizar`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    borrarAsignacionUsuario: ( {}, params ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `solicitudes/${params.solicitudId}/usuarios/${params.usuarioId}/delete`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    addObservaciones: ( {}, params ) => {
        return new Promise((resolve, reject) => {
            let observaciones = {
                observaciones: params.observaciones.trim()
            }

            apiAxios.post( `solicitudes/${params.solicitudId}/observaciones`, observaciones)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    addFile ( {}, params ) {
        return new Promise((resolve, reject) => {
            /* Validamos los archivos a subir */
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'jpg' 
                && fileExtension != 'png'
                && fileExtension != 'jpeg'
                && fileExtension != 'pdf'
                && fileExtension != 'doc'
                && fileExtension != 'docx'
                && fileExtension != 'xls'
                && fileExtension != 'xlsx') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg, documentos PDF y documentos de word y excel.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten adjuntos de hasta 3MB.';
            }

            let formData = new FormData();
            formData.append('file', params.file);
            
            let options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            }
            apiAxios.post(`solicitudes/${params.solicitudId}/files`, formData, options)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    deleteFile: ( {}, params ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `solicitudes/${params.solicitudId}/files/${params.fileId}/delete`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
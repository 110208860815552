import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import contribuyente from './contribuyente';

const state = {
    programa: null,
    nombre: '',
    tipoDocumento: null,
    numeroDocumento: '',
    email: '',
    numeroLegajo: '',
    numeroActa: '',
    importeAprobado: 0,
    fechaRegistro: '',
    fechaUltimaEdicion: '',
    campos: [],
    fechaActualizacionEstado: '1900-01-01',
    solicitudEstado: '',
    usuarioActualizacionEstado: '',
    departamento: '',
    proceso: '',
    procesoEstado: '',
    solicitudAuditoria: [],
    solicitudUsuarios: [],
    auditoria: '',
    files: [],
    observaciones: '',
    observacionesSolicitante: '',
    token: '',

    isContribuyente: false,
    contribuyenteEnabled: false
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.programa = null;
        state.nombre = '';
        state.tipoDocumento = null;
        state.numeroDocumento = '';
        state.email = '';
        state.numeroLegajo = '';
        state.numeroActa = '';
        state.importeAprobado = 0;
        state.fechaRegistro = '';
        state.fechaUltimaEdicion = '';
        state.campos = [];
        state.fechaActualizacionEstado = '1900-01-01';
        state.solicitudEstado = '';
        state.usuarioActualizacionEstado = '';
        state.departamento = '';
        state.proceso = '';
        state.procesoEstado = '';
        state.solicitudAuditoria = [];
        state.solicitudUsuarios = [];
        state.auditoria = '';
        state.files = [];
        state.observaciones = '';
        state.observacionesSolicitante = '';
        state.token = '';

        state.isContribuyente = false;
        state.contribuyenteEnabled = false;
    },
    'SET_DATOS'(state, solicitud) {
        state.programa = solicitud.programa;
        state.nombre = solicitud.nombre;
        state.tipoDocumento = solicitud.tipoDocumento;
        state.numeroDocumento = solicitud.numeroDocumento;
        state.email = solicitud.email;
        state.numeroLegajo = solicitud.numeroLegajo;
        state.numeroActa = solicitud.numeroActa;
        state.importeAprobado = parseFloat(solicitud.importeAprobado);
        state.fechaRegistro = moment(solicitud.fechaRegistro).format('DD/MM/YYYY HH:mm');
        state.fechaUltimaEdicion = moment(solicitud.fechaUltimaEdicion).format('DD/MM/YYYY HH:mm');
        state.campos = solicitud.campos.map(campo => ({
            ...campo,
            grupoOpciones: campo.camposGrupo.length != 0? true : false
        }));
        state.fechaActualizacionEstado = moment(solicitud.fechaActualizacionEstado).format('DD/MM/YYYY HH:mm');
        state.solicitudEstado = solicitud.solicitudEstado.trim();
        state.usuarioActualizacionEstado = solicitud.usuarioActualizacionEstado.trim();
        state.departamento = solicitud.departamento.trim();
        state.proceso = solicitud.proceso.trim();
        state.procesoEstado = solicitud.procesoEstado.trim();
        state.solicitudAuditoria = solicitud.auditoria;
        state.files = solicitud.files;
        state.solicitudUsuarios = solicitud.usuarios.map(solicitudUsuario => ({
            ...solicitudUsuario,
            finalizado: moment(solicitudUsuario.fechaFinalizacion).format('YYYY-MM-DD') == '1900-01-01'? false : true
        }));

        solicitud.auditoria.forEach(solicitudAuditoria => {
            state.auditoria += solicitudAuditoria.auditoria.trim();

            if (solicitudAuditoria.observaciones.trim() != '') {
                state.auditoria += '\n - ' + solicitudAuditoria.observaciones.trim();
            }

            state.auditoria += '\n';
        });

        solicitud.observaciones.forEach(solicitudObservaciones => {
            state.observaciones += solicitudObservaciones.observacion.trim();

            state.observaciones += '\n';
        });
        state.observacionesSolicitante = solicitud.observacionesSolicitante.trim();
        state.token = solicitud.token.trim();

        state.isContribuyente = (/true/i).test(solicitud.isContribuyente);
        state.contribuyenteEnabled = (/true/i).test(solicitud.contribuyenteEnabled);
    },
    'SET_USUARIOS'(state, usuarios) {
        state.solicitudUsuarios = usuarios;
    },    
    'SET_AUDITORIA'(state, auditoria) {
        state.solicitudAuditoria = auditoria;
    },    
};

const actions = {
    getDatos ( { commit }, solicitudId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (solicitudId) {
                apiAxios.get(`solicitudes/${solicitudId}`) 
                    .then(res => {
                        commit('SET_DATOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },
    getUsuarios ( { commit }, solicitudId ) {
        return new Promise((resolve, reject) => {
            if (solicitudId) {
                apiAxios.get(`solicitudes/${solicitudId}/usuarios`) 
                    .then(res => {
                        commit('SET_USUARIOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },
    getAuditoria ( { commit }, solicitudId ) {
        return new Promise((resolve, reject) => {
            if (solicitudId) {
                apiAxios.get(`solicitudes/${solicitudId}/auditoria`) 
                    .then(res => {
                        commit('SET_AUDITORIA', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },    
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    campoTipoId: 0,
    campoTipo: null,
    campoTipoNombre: null,
    listaId: 0,
    lista: null,
    listaNombre: null,
    longitudMaxima: 0,
    inactivo: false,
    rowVersion: null,

    /* Propiedades del tipo de campo */
    requiereLongitud: false,
    requiereLista: false
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, campoId) {
        state.id = campoId || 0;
        state.nombre = '';
        state.campoTipoId = 0;
        state.campoTipo = null;
        state.campoTipoNombre = null;
        state.listaId = 0;
        state.lista = null;
        state.listaNombre = null;
        state.longitudMaxima = 0;
        state.inactivo = false;
        state.rowVersion = null;

        state.requiereLongitud = false;
        state.requiereLista = false;
    },
    'SET_CAMPO'(state, campo) {
        // state.id = campo.id;
        state.nombre = campo.nombre;
        state.campoTipoId = campo.campoTipo? campo.campoTipo.id : 0;
        state.campoTipo = campo.campoTipo;
        state.campoTipoNombre = campo.campoTipo? campo.campoTipo.nombre.trim() : null;
        state.listaId = campo.lista? campo.lista.id : 0;
        state.lista = campo.lista;
        state.listaNombre = campo.lista? campo.lista.nombre.trim() : null;
        state.longitudMaxima = campo.longitudMaxima;
        state.inactivo = (/true/i).test(campo.inactivo);
        state.rowVersion = campo.rowVersion;
        
        state.requiereLongitud = (/true/i).test(campo.campoTipo? campo.campoTipo.requiereLongitud : false);
        state.requiereLista = (/true/i).test(campo.campoTipo? campo.campoTipo.requiereLista : false);
    },
    'SET_CAMPO_TIPO'(state, campoTipo) {
        state.campoTipo = campoTipo;
        if (campoTipo) {
            state.campoTipoId = campoTipo.id? campoTipo.id : 0;
            state.campoTipoNombre = campoTipo.nombre? campoTipo.nombre.trim() : null;
            
            state.requiereLongitud = (/true/i).test(campoTipo.requiereLongitud);
            state.requiereLista = (/true/i).test(campoTipo.requiereLista);

            /* Reinicializamos los parametros de configuración */
            if (!state.requiereLongitud) {
                state.longitudMaxima = 0;
            }

            if (!state.requiereLista) {
                state.listaId = 0;
                state.lista = null;
                state.listaNombre = null;
            }
        } else {
            state.campoTipoId = 0;
            state.campoTipoNombre = null;
            
            state.requiereLongitud = false;
            state.requiereLista = false;
            state.listaId = 0;
            state.lista = null;
            state.listaNombre = null;
            state.longitudMaxima = 0;
        }
    },
    'SET_LISTA'(state, lista) {
        state.lista = lista;
        if (lista) {
            state.listaId = lista.id? lista.id : 0;
            state.listaNombre = lista.nombre? lista.nombre.trim() : null;
        } else {
            state.listaId = '';
            state.listaNombre = null;
        }
    },    
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, campoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', campoId);
            
            if (campoId != 0) {
                apiAxios.get(`campos/${campoId}/abm`) 
                    .then(res => {
                        commit('SET_CAMPO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    setCampoTipo ( { commit }, campoTipo ) {
        return new Promise((resolve, reject) => {
            commit('SET_CAMPO_TIPO', campoTipo);
            resolve();
        })
    },
    setLista ( { commit }, lista ) {
        return new Promise((resolve, reject) => {
            commit('SET_LISTA', lista);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let campo= {
                    nombre: state.nombre.toUpperCase().trim(),
                    campoTipoId: parseInt(state.campoTipoId),
                    listaId: parseInt(state.listaId),
                    longitudMaxima: parseInt(state.longitudMaxima),
                    inactivo: (/true/i).test(state.inactivo)
                };

                let url = 'campos';
                if (state.id != '') {
                    url += '/' + state.id;
                    campo.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, campo)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    }   
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
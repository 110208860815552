import Vue from 'vue';
import Vuex from 'vuex';

import campo from './modules/campo';
import campoDatos from './modules/campoDatos';
import campos from './modules/campos';
import camposTipos from './modules/camposTipos';
import contribuyente from './modules/contribuyente';
import contribuyenteDatos from './modules/contribuyenteDatos';
import contribuyentes from './modules/contribuyentes';
import dashboard from './modules/dashboard';
import departamento from './modules/departamento';
import departamentoDatos from './modules/departamentoDatos';
import departamentos from './modules/departamentos';
import formulario from './modules/formulario';
import formularioDatos from './modules/formularioDatos';
import formularios from './modules/formularios';
import formularioCampo from './modules/formularioCampo';
import formularioCampoGrupo from './modules/formularioCampoGrupo';
import lista from './modules/lista';
import listaDatos from './modules/listaDatos';
import listas from './modules/listas';
import proceso from './modules/proceso';
import procesoDatos from './modules/procesoDatos';
import procesos from './modules/procesos';
import procesoEstado from './modules/procesoEstado';
import procesoEstadoDatos from './modules/procesoEstadoDatos';
import procesosEstados from './modules/procesosEstados';
import programa from './modules/programa';
import programaDatos from './modules/programaDatos';
import programas from './modules/programas';
import solicitud from './modules/solicitud';
import solicitudes from './modules/solicitudes';
import solicitudActualizacion from './modules/solicitudActualizacion';
import solicitudActualizacionEstado from './modules/solicitudActualizacionEstado';
import solicitudDatos from './modules/solicitudDatos';
import solicitudEstado from './modules/solicitudEstado';
import solicitudEstadoDatos from './modules/solicitudEstadoDatos';
import solicitudesEstados from './modules/solicitudesEstados';
import tiposDocumentos from './modules/tiposDocumentos';

import empresa from './modules/empresa';
import spinnerProcesando from './modules/spinner-procesando';
import user from './modules/user';
import usuario from './modules/usuario';
import usuarios from './modules/usuarios';
import usuariosRoles from './modules/usuariosRoles';
import usuarioPerfil from './modules/usuarioPerfil';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        campo,
        campoDatos,
        campos,
        camposTipos,
        contribuyente,
        contribuyenteDatos,
        contribuyentes,
        dashboard,
        departamento,
        departamentoDatos,
        departamentos,
        formulario,
        formularioDatos,
        formularios,
        formularioCampo,
        formularioCampoGrupo,
        lista,
        listaDatos,
        listas,
        proceso,
        procesoDatos,
        procesos,
        procesoEstado,
        procesoEstadoDatos,
        procesosEstados,
        programa,
        programaDatos,
        programas,
        solicitud,
        solicitudes,
        solicitudActualizacion,
        solicitudActualizacionEstado,
        solicitudDatos,
        solicitudEstado,
        solicitudesEstados,
        solicitudEstadoDatos,
        tiposDocumentos,

        empresa,
        spinnerProcesando,
        user,
        usuario,
        usuarios,
        usuariosRoles,
        usuarioPerfil
    }
});
import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    campoDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.campoDatos = [];
    },
    'SET_DATOS'(state, params) {
        state.campoDatos = params.campoDatos;
    },
};

const actions = {
    getDatos ( { commit }, campoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (campoId) {
                apiAxios.get(`campos/${campoId}` ) 
                    .then(res => {
                        let campoDatos = [
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'campoTipo', 'campo': 'Tipo de Campo', 'valor': res.data.campoTipo, 'formato': '' },
                            { 'nombre': 'lista', 'campo': 'Lista', 'valor': res.data.lista, 'formato': '' },
                            { 'nombre': 'longitudMaxima', 'campo': 'Long.Maxima', 'valor': res.data.longitudMaxima, 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', 
                        {
                            campoDatos,
                        });
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
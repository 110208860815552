import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    descripcion: '',
    campoId: 0,
    campo: null,
    campoNombre: null,
    campoTipoId: 0,
    campoTipo: null,
    campoTipoNombre: null,
    listaId: 0,
    lista: null,
    listaNombre: null,
    longitudMaxima: 0,
    orden: 0,
    requerido: false,

    /* Propiedades del tipo de campo */
    requiereLongitud: false,
    requiereLista: false
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.id = '';
        state.nombre = '';
        state.descripcion = '';
        state.campoId = 0;
        state.campo = null;
        state.campoNombre = null;
        state.campoTipoId = 0;
        state.campoTipo = null;
        state.campoTipoNombre = null;
        state.listaId = 0;
        state.lista = null;
        state.listaNombre = null;
        state.longitudMaxima = 0;
        state.orden = 0;
        state.requerido = false;

        state.requiereLongitud = false;
        state.requiereLista = false;
    },
    'SET_FORMULARIO_CAMPO'(state, formularioCampo) {
        state.id = formularioCampo.id;
        state.nombre = formularioCampo.nombre;
        state.descripcion = formularioCampo.descripcion;
        state.campoId = formularioCampo.campoId;
        state.campo = formularioCampo.campo;
        state.campoNombre = formularioCampo.campo? formularioCampo.campo.nombre.trim() : null;
        state.campoTipoId = formularioCampo.campoTipoId;
        state.campoTipo = formularioCampo.campoTipo;
        state.campoTipoNombre = formularioCampo.campoTipo? formularioCampo.campoTipo.nombre.trim() : null;
        state.listaId = formularioCampo.listaId;
        state.lista = formularioCampo.lista;
        state.listaNombre = formularioCampo.lista? formularioCampo.lista.nombre.trim() : null;
        state.longitudMaxima = formularioCampo.longitudMaxima;
        state.orden = formularioCampo.orden;
        state.requerido = (/true/i).test(formularioCampo.requerido);
        
        state.requiereLongitud = (/true/i).test(formularioCampo.campoTipo? formularioCampo.campoTipo.requiereLongitud : false);
        state.requiereLista = (/true/i).test(formularioCampo.campoTipo? formularioCampo.campoTipo.requiereLista : false);
    },
    'INICIALIZAR_CAMPO'(state) {
        state.campoId = 0;
        state.campo = null;
        state.campoNombre = null;

        state.campoTipoId = 0;
        state.campoTipo = null;
        state.campoTipoNombre = null;
        state.listaId = 0;
        state.lista = null;
        state.listaNombre = null;
        state.longitudMaxima = 0;
        state.requerido = false;

        state.requiereLongitud = false;
        state.requiereLista = false;
    },
    'SET_CAMPO'(state, campo) {
        state.campo = campo;
        if (campo) {
            state.campoId = campo.id? campo.id : 0;
            state.campoNombre = campo.nombre? campo.nombre.trim() : null;

            /* Parámetros del campo */
            state.campoTipoId = campo.campoTipoId;
            state.campoTipo = campo.campoTipo;
            state.campoTipoNombre = campo.campoTipo.nombre? campo.campoTipo.nombre.trim() : null;
            state.longitudMaxima = campo.longitudMaxima;
            state.requiereLongitud = (/true/i).test(campo.campoTipo.requiereLongitud);
            state.requiereLista = (/true/i).test(campo.campoTipo.requiereLista);

            if (!state.requiereLista) {
                state.listaId = 0;
                state.lista = null;
                state.listaNombre = null;
            } else {
                state.listaId = campo.listaId;
                state.lista = campo.lista;
                state.listaNombre = campo.lista.nombre? campo.lista.nombre.trim() : null;
            }

            if (!state.requiereLongitud) {
                state.longitudMaxima = 0;
            }
        } else {
            state.campoId = 0;
            state.campoNombre = null;
        }
    },
    'SET_CAMPO_TIPO'(state, campoTipo) {
        state.campoTipo = campoTipo;
        if (campoTipo) {
            state.campoTipoId = campoTipo.id? campoTipo.id : 0;
            state.campoTipoNombre = campoTipo.nombre? campoTipo.nombre.trim() : null;
            
            state.requiereLongitud = (/true/i).test(campoTipo.requiereLongitud);
            state.requiereLista = (/true/i).test(campoTipo.requiereLista);

            /* Reinicializamos los parametros de configuración */
            if (!state.requiereLongitud) {
                state.longitudMaxima = 0;
            }

            if (!state.requiereLista) {
                state.listaId = 0;
                state.lista = null;
                state.listaNombre = null;
            }
        } else {
            state.campoTipoId = 0;
            state.campoTipoNombre = null;
            
            state.requiereLongitud = false;
            state.requiereLista = false;
            state.listaId = 0;
            state.lista = null;
            state.listaNombre = null;
            state.longitudMaxima = 0;
        }
    },
    'SET_LISTA'(state, lista) {
        state.lista = lista;
        if (lista) {
            state.listaId = lista.id? lista.id : 0;
            state.listaNombre = lista.nombre? lista.nombre.trim() : null;
        } else {
            state.listaId = '';
            state.listaNombre = null;
        }
    },
};

const getters = {
    getField,

    formularioCampo (state) {
        return {
            id: state.id,
            nombre: state.nombre.trim(),
            descripcion: state.descripcion.trim(),
            campo: state.campo,
            campoTipo: state.campoTipo,
            lista: state.lista,
            longitudMaxima: parseInt(state.longitudMaxima),
            orden: parseInt(state.orden),
            requerido: (/true/i).test(state.requerido)
        }
    }
};

const actions = {
    inicializar ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            if (campo) {
                commit('SET_FORMULARIO_CAMPO', campo);
                resolve();
            } else {
                resolve();
            }
        })
    },
    setCampo ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            let getCampo = false;

            if (campo) {
                if (campo.id) {
                    getCampo = campo.id != 0;
                }
            }

            if (getCampo) {
                apiAxios.get(`campos/${campo.id}/formulario`) 
                    .then(res => {
                        commit('SET_CAMPO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                commit('INICIALIZAR_CAMPO');
                resolve();
            }
        })        
    },
    setCampoTipo ( { commit }, campoTipo ) {
        return new Promise((resolve, reject) => {
            commit('SET_CAMPO_TIPO', campoTipo);
            resolve();
        })
    },
    setLista ( { commit }, lista ) {
        return new Promise((resolve, reject) => {
            commit('SET_LISTA', lista);
            resolve();
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
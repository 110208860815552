import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    cuit: '',
    nombre: '',
    email: '',
    celular: '',
    campos: [],
    rowVersion: null,

    uploadingFiles: false
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, contribuyenteId) {
        state.id = contribuyenteId;
        state.cuit = '';
        state.nombre = '';
        state.email = '';
        state.celular = '';
        state.campos = [];
        state.rowVersion = null;
    },
    'SET_CONTRIBUYENTE'(state, contribuyente) {
        state.cuit = contribuyente.cuit.trim();
        state.nombre = contribuyente.nombre.trim();
        state.email = contribuyente.email.trim();
        state.celular = contribuyente.celular.trim();
        state.rowVersion = contribuyente.rowVersion;

        state.campos = contribuyente.campos.map(campo => ({
            id: helpers.methods.getUUID(),
            contribuyenteCampoId: campo.id,
            campoId: campo.campoId,
            nombre: campo.nombre.trim(),
            campoTipo: campo.campoTipo.trim(),
            longitudMaxima: parseInt(campo.longitudMaxima),
            lista: campo.lista,
            valor: campo.valor,

            selectList: getSelectList(campo),
            file: null,
            url: campo.url,
            publicId: campo.publicId,
            inputId: `c-${campo.campoId.toString()}`,
            valido: null,
            invalidoMsj: '',
        }));
    },
    'ADD_CAMPO' (state, campo) {
        let itemKey = state.campos.findIndex(t => t.campoId == campo.campoId);
        
        if (itemKey == -1) {
            state.campos.push({
                id: helpers.methods.getUUID(),
                contribuyenteCampoId: 0,
                campoId: campo.campoId,
                nombre: campo.nombre.trim(),
                campoTipo: campo.campoTipo.trim(),
                longitudMaxima: parseInt(campo.longitudMaxima),
                lista: campo.lista,
                valor: campo.campoTipo.trim().toLowerCase() == 'lista'? null : campo.valor,
    
                selectList: getSelectList(campo),
                file: null,
                url: campo.url,
                publicId: campo.publicId,
                inputId: `c-${campo.campoId.toString()}`,
                valido: null,
                invalidoMsj: '',
            })
        }
    },
    'REMOVE_CAMPO' (state, campoId) {
        let itemKey = state.campos.findIndex(t => t.campoId == campoId);
        
        if (itemKey != -1) {
            state.campos.splice(itemKey, 1);
        }
    },
    'SET_UPLOADING'(state) {
        state.uploadingFiles = true;
    },
    'SET_VALOR_FILE'(state, params) {
        let campo = state.campos.find(t => t.campoId == params.campoId);
        if (campo) {
            campo.valor = params.valor;
            campo.url = params.url;
            campo.publicId = params.publicId;
        }
        state.uploadingFiles = false;
    },
    'DELETE_FILE'(state, campoId) {
        let campo = state.campos.find(t => t.campoId == campoId);
        if (campo) {
            campo.file = null;
            campo.valor = '';
            campo.url = '';
            campo.publicId = '';
        }
    },
    'SET_VALOR_LISTA'(state, params) {
        if (params.item) {
            let campo = state.campos.find(t => t.campoId == params.campo.campoId);
            if (campo) {
                campo.valor = params.item.nombre;
            }
        }
    },    
};

function getSelectList(campo) {
    if (campo.lista) {
        return campo.lista.map(item => item.nombre.trim());
    }
    
    return [];
}

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, contribuyenteId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', contribuyenteId);
            
            if (contribuyenteId != '') {
                apiAxios.get(`contribuyentes/${contribuyenteId}/abm`) 
                    .then(res => {
                        commit('SET_CONTRIBUYENTE', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })            
    },
    addCampo ( { commit }, campoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.get(`campos/${campoId}/contribuyente`) 
                .then(res => {
                    commit('ADD_CAMPO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })            
    },
    removeCampo ( { commit }, campoId ) {
        return new Promise((resolve, reject) => {
            commit('REMOVE_CAMPO', campoId);
            resolve();
        })
    },
    setValorLista ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('SET_VALOR_LISTA', params);
            resolve();
        })
    },
    uploadFile ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            /* Validamos los archivos a subir */
            let fileName = campo.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(campo.file.size / 1048576);

            if (fileExtension != 'jpg' 
                & fileExtension != 'png'
                & fileExtension != 'jpeg'
                & fileExtension != 'pdf') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 3MB.';
            }

            commit('SET_UPLOADING');

            let formData = new FormData();
            formData.append('file', campo.file);

            let options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            }
            apiAxios.post('contribuyentes/files', formData, options)
                .then(res => {
                    commit('SET_VALOR_FILE', {
                        campoId: campo.campoId,
                        valor: res.data.fileName,
                        url: res.data.url,
                        publicId: res.data.publicId
                    });
                    resolve();
                })
                .catch(error => {
                    commit('DELETE_FILE', campo.campoId);
                    
                    reject(helpers.methods.getErrorMessage(error));
                });
        })
    },
    deleteFile ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('DELETE_FILE', campo.campoId);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';

            var filePendiente = state.campos
                .find(t => t.campoTipo == 'File' && t.valor == '' && t.file);
            if (filePendiente) {
                save = false;
                errorMessage = 'Quedan documentos pendientes de procesar. Espere a que terminen de procesarse para registrar la solicitud';
            }
            
            if (save) {
                let contribuyente= {
                    cuit: state.cuit.trim(),
                    nombre: state.nombre.trim(),
                    email: state.email.toLowerCase().trim(),
                    celular: state.celular.trim(),
                };

                if (state.campos) {
                    contribuyente.campos = state.campos.map(campo => ({
                        campoId: campo.campoId,
                        valor: campo.valor,
                        publicId: campo.publicId
                    }))
                }

                let url = 'contribuyentes';
                if (state.id != '') {
                    url += '/' + state.id;
                    contribuyente.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, contribuyente)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
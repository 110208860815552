import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    solicitudId: '',
    solicitudEstadoId: 0,
    solicitudEstado: null,
    solicitudEstadoNombre: null,
    observaciones: '',
    observacionesSolicitante: '',
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, solicitudId) {
        state.solicitudId = solicitudId;
        state.solicitudEstadoId = 0;
        state.solicitudEstado = null;
        state.solicitudEstadoNombre = null;
        state.observaciones = '';
        state.observacionesSolicitante = '';
        state.rowVersion = null;
    },
    'SET_SOLICITUD'(state, solicitud) {
        state.solicitudEstadoId = solicitud.solicitudEstadoId;
        state.solicitudEstado = solicitud.solicitudEstado;
        state.solicitudEstadoNombre = solicitud.solicitudEstado? solicitud.solicitudEstado.nombre.trim() : null;
        state.observaciones = '';
        state.observacionesSolicitante = '';
        state.rowVersion = solicitud.rowVersion;
    },
    'SET_SOLICITUD_ESTADO'(state, solicitudEstado) {
        state.solicitudEstado = solicitudEstado;
        if (solicitudEstado) {
            state.solicitudEstadoId = solicitudEstado.id? solicitudEstado.id : 0;
            state.solicitudEstadoNombre = solicitudEstado.nombre? solicitudEstado.nombre.trim() : null;
        } else {
            state.solicitudEstadoId = 0;
            state.solicitudEstadoNombre = null;
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, solicitudId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', solicitudId);
            
            if (solicitudId != '') {
                apiAxios.get(`solicitudes/${solicitudId}/actualizacion-estado`) 
                    .then(res => {
                        commit('SET_SOLICITUD', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    setSolicitudEstado ( { commit }, solicitudEstado ) {
        return new Promise((resolve, reject) => {
            commit('SET_SOLICITUD_ESTADO', solicitudEstado);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let solicitudActualizacion= {
                    solicitudEstadoId: state.solicitudEstadoId,
                    observaciones: state.observaciones.trim(),
                    observacionesSolicitante: state.observacionesSolicitante.trim(),
                    rowVersion: state.rowVersion
                };

                apiAxios.post(`solicitudes/${state.solicitudId}/actualizacion-estado`, solicitudActualizacion)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
export function getAccesos(userRolId) {
    let accesos = [];
    switch (userRolId) {
        case 1: /* Superadmin */
        case 2: /* SisAdmin */
            break;
        
        case 3: /* Supervisor */ 
            accesos = [
                { route: 'dashboard', modulo: '' },

                { route: 'programas', modulo: '' },
                { route: 'programas/add', modulo: '' },
                { route: 'programas/:id/edit', modulo: '' },
                { route: 'programas/:id', modulo: '' },

                { route: 'formularios', modulo: '' },
                { route: 'formularios/add', modulo: '' },
                { route: 'formularios/:id/edit', modulo: '' },
                { route: 'formularios/:id', modulo: '' },

                // { route: 'contribuyentes', modulo: '' },
                // { route: 'contribuyentes/add', modulo: '' },
                // { route: 'contribuyentes/:id/edit', modulo: '' },
                // { route: 'contribuyentes/:id', modulo: '' },

                { route: 'solicitudes', modulo: '' },
                { route: 'solicitudes/add/:formularioId', modulo: '' },
                { route: 'solicitudes/:id/edit', modulo: '' },
                { route: 'solicitudes/:id', modulo: '' },

                { route: 'tablas', modulo: '' },
                // { route: 'tablas/campos', modulo: '' },
                // { route: 'tablas/campos/add', modulo: '' },
                // { route: 'tablas/campos/:id/edit', modulo: '' },
                // { route: 'tablas/campos/:id', modulo: '' },
                { route: 'tablas/departamentos', modulo: '' },
                { route: 'tablas/departamentos/add', modulo: '' },
                { route: 'tablas/departamentos/:id/edit', modulo: '' },
                { route: 'tablas/departamentos/:id', modulo: '' },
                { route: 'tablas/procesos', modulo: '' },
                { route: 'tablas/procesos/add', modulo: '' },
                { route: 'tablas/procesos/:id/edit', modulo: '' },
                { route: 'tablas/procesos/:id', modulo: '' },
                { route: 'tablas/procesos-estados', modulo: '' },
                { route: 'tablas/procesos-estados/add', modulo: '' },
                { route: 'tablas/procesos-estados/:id/edit', modulo: '' },
                { route: 'tablas/procesos-estados/:id', modulo: '' },
                { route: 'tablas/solicitudes-estados', modulo: '' },
                { route: 'tablas/solicitudes-estados/add', modulo: '' },
                { route: 'tablas/solicitudes-estados/:id/edit', modulo: '' },
                { route: 'tablas/solicitudes-estados/:id', modulo: '' },
                { route: 'tablas/listas', modulo: '' },
                { route: 'tablas/listas/add', modulo: '' },
                { route: 'tablas/listas/:id/edit', modulo: '' },
                { route: 'tablas/listas/:id', modulo: '' },
            ]
            break;

        case 4: /* Administrador */ 
            accesos = [
                { route: 'dashboard', modulo: '' },

                // { route: 'contribuyentes', modulo: '' },
                // { route: 'contribuyentes/add', modulo: '' },
                // { route: 'contribuyentes/:id/edit', modulo: '' },
                // { route: 'contribuyentes/:id', modulo: '' },

                { route: 'solicitudes', modulo: '' },
                { route: 'solicitudes/add/:formularioId', modulo: '' },
                { route: 'solicitudes/:id/edit', modulo: '' },
                { route: 'solicitudes/:id', modulo: '' },
            ]
            break;

    }
    
    /* Accesos disponibles para todos los usuarios */
    accesos.push({ route: '/', modulo: '' });
    accesos.push({ route: 'perfil', modulo: '' });
    accesos.push({ route: 'seguridad/usuarios/self-pass', modulo: '' });

    return accesos;
}

import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    listaDatos: [],
    valores: []
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.listaDatos = [];
        state.valores = [];
    },
    'SET_DATOS'(state, params) {
        state.listaDatos = params.listaDatos;
        state.valores = params.valores.sort((a, b) => a.nombre.localeCompare(b.nombre));
    },
};

const actions = {
    getDatos ( { commit }, listaId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (listaId) {
                apiAxios.get(`listas/${listaId}` ) 
                    .then(res => {
                        let listaDatos = [
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                        ];

                        commit('SET_DATOS', 
                        {
                            listaDatos,
                            valores: res.data.valores
                        });
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
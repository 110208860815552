import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';

const state = {
    procesoEstadoDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.procesoEstadoDatos = [];
    },
    'SET_DATOS'(state, procesoEstadoDatos) {
        state.procesoEstadoDatos = procesoEstadoDatos;
    },
};

const actions = {
    getDatos ( { commit }, procesoEstadoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (procesoEstadoId) {
                apiAxios.get(`procesos-estados/${procesoEstadoId}`) 
                    .then(res => {
                        let datos = [
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'inicial', 'campo': 'Inicial', 'valor': res.data.inicial? 'Si' : 'No', 'formato': '' },
                            { 'nombre': 'final', 'campo': 'Final', 'valor': res.data.final? 'Si' : 'No', 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', datos);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
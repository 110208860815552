import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    programaId: '',
    programa: null,
    programaNombre: null,
    nombre: '',
    personaFisica: false,
    personaJuridica: false,
    inactivo: false,
    rowVersion: null,

    campos: [],
    condiciones: [],

    tieneSolicitudes: false
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, formularioId) {
        state.id = formularioId;
        state.programaId = '';
        state.programa = null;
        state.programaNombre = null;
        state.nombre = '';
        state.personaFisica = false;
        state.personaJuridica = false;
        state.inactivo = false;
        state.rowVersion = null;

        state.campos = [];
        state.condiciones = [];

        state.tieneSolicitudes = false;
    },
    'SET_FORMULARIO'(state, formulario) {
        state.programaId = formulario.programa? formulario.programa.id : '';
        state.programa = formulario.programa;
        state.programaNombre = formulario.programa? formulario.programa.nombre.trim() : null;
        state.nombre = formulario.nombre.trim();
        state.personaFisica = (/true/i).test(formulario.personaFisica);
        state.personaJuridica = (/true/i).test(formulario.personaJuridica);
        state.inactivo = (/true/i).test(formulario.inactivo);
        state.rowVersion = formulario.rowVersion;

        state.campos = formulario.campos.map(formularioCampo => ({
            id: helpers.methods.getUUID(),
            formularioCampoId: formularioCampo.id,
            nombre: formularioCampo.nombre.trim(),
            descripcion: formularioCampo.descripcion.trim(),
            campoId: formularioCampo.campoId,
            campo: formularioCampo.campo,
            campoNombre: formularioCampo.campo? formularioCampo.campo.nombre.trim() : null,
            campoTipoId: formularioCampo.campoTipoId,
            campoTipo: formularioCampo.campoTipo,
            campoTipoNombre: formularioCampo.campoTipo? formularioCampo.campoTipo.nombre.trim() : null,
            listaId: formularioCampo.listaId,
            lista: formularioCampo.lista,
            listaNombre: formularioCampo.lista? formularioCampo.lista.nombre.trim() : null,
            longitudMaxima: parseInt(formularioCampo.longitudMaxima),
            orden: parseInt(formularioCampo.orden),
            requerido: (/true/i).test(formularioCampo.requerido),
            camposGrupo: formularioCampo.camposGrupo.map(formularioCampoGrupo => ({
                id: helpers.methods.getUUID(),
                formularioCampoId: formularioCampoGrupo.id,
                nombre: formularioCampoGrupo.nombre.trim(),
                campoTipoId: formularioCampoGrupo.campoTipoId,
                campoTipo: formularioCampoGrupo.campoTipo,
                campoTipoNombre: formularioCampoGrupo.campoTipo? formularioCampoGrupo.campoTipo.nombre.trim() : null,
                longitudMaxima: parseInt(formularioCampoGrupo.longitudMaxima),
                orden: parseInt(formularioCampoGrupo.orden),
            }))
        }));

        state.condiciones = formulario.condiciones;

        state.tieneSolicitudes = (/true/i).test(formulario.tieneSolicitudes);
    },
    'SET_PROGRAMA'(state, programa) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }

        state.programa = programa;
        if (programa) {
            state.programaId = programa.id? programa.id : 0;
            state.programaNombre = programa.nombre? programa.nombre.trim() : null;
        } else {
            state.programaId = 0;
            state.programaNombre = null;
        }
    },
    'ADD_CAMPO' (state, formularioCampo) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }

        let orden = state.campos.length + 1;

        state.campos.push({
            id: helpers.methods.getUUID(),
            formularioCampoId: 0,
            nombre: formularioCampo.nombre.trim(),
            descripcion: formularioCampo.descripcion.trim(),
            campoId: formularioCampo.campo? formularioCampo.campo.id : 0,
            campo: formularioCampo.campo,
            campoNombre: formularioCampo.campo? formularioCampo.campo.nombre.trim() : null,
            campoTipoId: formularioCampo.campoTipo? formularioCampo.campoTipo.id : 0,
            campoTipo: formularioCampo.campoTipo,
            campoTipoNombre: formularioCampo.campoTipo? formularioCampo.campoTipo.nombre.trim() : null,
            listaId: formularioCampo.lista? formularioCampo.lista.id : '',
            lista: formularioCampo.lista,
            listaNombre: formularioCampo.lista? formularioCampo.lista.nombre.trim() : null,
            longitudMaxima: parseInt(formularioCampo.longitudMaxima),
            // orden: parseInt(campo.orden),
            orden,
            requerido: (/true/i).test(formularioCampo.requerido),
            camposGrupo: []
        })
    },
    'UPDATE_CAMPO' (state, formularioCampo) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }

        let item = state.campos.find(t => t.id == formularioCampo.id);

        if (item) {
            item.nombre = formularioCampo.nombre.trim();
            item.descripcion = formularioCampo.descripcion.trim();
            item.campoId = formularioCampo.campo? formularioCampo.campo.id : 0;
            item.campo = formularioCampo.campo;
            item.campoNombre = formularioCampo.campo? formularioCampo.campo.nombre.trim() : null;
            item.campoTipoId = formularioCampo.campoTipo? formularioCampo.campoTipo.id : 0;
            item.campoTipo = formularioCampo.campoTipo;
            item.campoTipoNombre = formularioCampo.campoTipo? formularioCampo.campoTipo.nombre.trim() : null;
            item.listaId = formularioCampo.lista? formularioCampo.lista.id : '';
            item.lista = formularioCampo.lista;
            item.listaNombre = formularioCampo.lista? formularioCampo.lista.nombre.trim() : null;
            item.longitudMaxima = parseInt(formularioCampo.longitudMaxima);
            item.requerido = (/true/i).test(formularioCampo.requerido);
        }
    },
    'REMOVE_CAMPO' (state, formularioCampoId) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }

        let itemKey = state.campos.findIndex(t => t.id == formularioCampoId);
        
        if (itemKey != -1) {
            state.campos.splice(itemKey, 1);

            /* Renumeramos el orden de los campos */
            let orden = 0;
            state.campos
                .sort((a, b) => a.orden - b.orden)
                .forEach(formularioCampo => {
                    orden++;
                    formularioCampo.orden = orden;
                });
        }
    },
    'ADD_CAMPO_GRUPO' (state, params) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }

        let formularioCampo = state.campos.find(t => t.id == params.formularioCampo.id);

        if (formularioCampo) {
            let orden = formularioCampo.camposGrupo.length + 1;

            formularioCampo.camposGrupo.push({
                id: helpers.methods.getUUID(),
                formularioCampoId: 0,
                nombre: params.formularioCampoGrupo.nombre.trim(),
                campoTipoId: params.formularioCampoGrupo.campoTipo? params.formularioCampoGrupo.campoTipo.id : 0,
                campoTipo: params.formularioCampoGrupo.campoTipo,
                campoTipoNombre: params.formularioCampoGrupo.campoTipo? params.formularioCampoGrupo.campoTipo.nombre.trim() : null,
                longitudMaxima: parseInt(params.formularioCampoGrupo.longitudMaxima),
                orden
            })
        }
    },
    'UPDATE_CAMPO_GRUPO' (state, params) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }

        let formularioCampo = state.campos.find(t => t.id == params.formularioCampo.id);

        if (formularioCampo) {
            let formularioCampoGrupo = formularioCampo.camposGrupo.find(t => t.id == params.formularioCampoGrupo.id);
            if (formularioCampoGrupo) {
                formularioCampoGrupo.nombre = params.formularioCampoGrupo.nombre.trim();
                formularioCampoGrupo.campoTipoId = params.formularioCampoGrupo.campoTipo? params.formularioCampoGrupo.campoTipo.id : 0;
                formularioCampoGrupo.campoTipo = params.formularioCampoGrupo.campoTipo;
                formularioCampoGrupo.campoTipoNombre = params.formularioCampoGrupo.campoTipo? params.formularioCampoGrupo.campoTipo.nombre.trim() : null;
                formularioCampoGrupo.longitudMaxima = parseInt(params.formularioCampoGrupo.longitudMaxima);
            }
        }
    },
    'REMOVE_CAMPO_GRUPO' (state, params) {
        if ((/true/i).test(state.tieneSolicitudes)) {
            return;
        }
        let formularioCampo = state.campos.find(t => t.id == params.formularioCampo.id);

        if (formularioCampo) {
            let itemKey = formularioCampo.camposGrupo.findIndex(t => t.id == params.formularioCampoGrupoId);
            
            if (itemKey != -1) {
                formularioCampo.camposGrupo.splice(itemKey, 1);

                /* Renumeramos el orden de los campos */
                let orden = 0;
                formularioCampo.camposGrupo
                    .sort((a, b) => a.orden - b.orden)
                    .forEach(campoGrupo => {
                        orden++;
                        campoGrupo.orden = orden;
                    });
            }
        }
    },

};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, formularioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', formularioId);
            
            if (formularioId != '') {
                apiAxios.get(`formularios/${formularioId}/abm`) 
                    .then(res => {
                        commit('SET_FORMULARIO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    setPrograma ( { commit }, programa ) {
        return new Promise((resolve, reject) => {
            commit('SET_PROGRAMA', programa);
            resolve();
        })
    },
    addCampo ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('ADD_CAMPO', campo);
            resolve();
        })
    },
    updateCampo ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('UPDATE_CAMPO', campo);
            resolve();
        })
    },
    removeCampo ( { commit }, campoId ) {
        return new Promise((resolve, reject) => {
            commit('REMOVE_CAMPO', campoId);
            resolve();
        })
    },
    addCampoGrupo ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('ADD_CAMPO_GRUPO', params);
            resolve();
        })
    },
    updateCampoGrupo ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('UPDATE_CAMPO_GRUPO', params);
            resolve();
        })
    },
    removeCampoGrupo ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('REMOVE_CAMPO_GRUPO', params);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let campos = [];
                state.campos.forEach(formularioCampo => {
                    let campoToAdd = {
                        id: formularioCampo.formularioCampoId,
                        campoId: parseInt(formularioCampo.campoId),
                        campoTipoId: parseInt(formularioCampo.campoTipoId),
                        // listaId: campo.listaId,
                        nombre: formularioCampo.nombre.trim(),
                        descripcion: formularioCampo.descripcion.trim(),
                        longitudMaxima: parseInt(formularioCampo.longitudMaxima),
                        orden: parseInt(formularioCampo.orden),
                        requerido: (/true/i).test(formularioCampo.requerido),
                        camposGrupo: []
                    }

                    if (formularioCampo.listaId != '') {
                        campoToAdd.listaId = formularioCampo.listaId
                    }

                    if (formularioCampo.camposGrupo) {
                        formularioCampo.camposGrupo.forEach(campoGrupo => {
                            let campoGrupoToAdd = {
                                id: campoGrupo.formularioCampoId,
                                campoTipoId: parseInt(campoGrupo.campoTipoId),
                                nombre: campoGrupo.nombre.trim(),
                                longitudMaxima: parseInt(campoGrupo.longitudMaxima),
                                orden: parseInt(campoGrupo.orden),
                            }
                            
                            campoToAdd.camposGrupo.push(campoGrupoToAdd);
                        });
                    }

                    campos.push(campoToAdd);
                });

                let formulario= {
                    programaId: state.programaId,
                    nombre: state.nombre.toUpperCase().trim(),
                    personaFisica: (/true/i).test(state.personaFisica),
                    personaJuridica: (/true/i).test(state.personaJuridica),
                    inactivo: (/true/i).test(state.inactivo),
                    campos,
                    condiciones: state.condiciones
                };

                let url = 'formularios';
                if (state.id != '') {
                    url += '/' + state.id;
                    formulario.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, formulario)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}